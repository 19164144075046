<template>
    <div class="main-layout">
        <div class="content">
            <slot></slot>
        </div>
        <div class="cookies" v-show="!cookies">
            <div class="message">
                <p>Om met onze website voor jou een betere ervaring te creëren, gebruiken we altijd functionele en analytische cookies (en vergelijkbare technieken). Ook willen we graag cookies plaatsen om je bezoek aan onze website makkelijker en persoonlijker te maken. Met deze cookies kunnen wij en derde partijen jouw internetgedrag binnen en buiten onze website volgen en verzamelen. Hiermee passen wij en derden onze website en advertenties aan op jouw interesse. We slaan je cookievoorkeuren voor je op, als je ons via een ander apparaat gebruikt, hoef je niet opnieuw een keuze te maken. Door op ‘accepteren’ te klikken ga je hiermee akkoord. Je kunt je cookievoorkeuren altijd nalezen en aanpassen via ons cookiebeleid.<br /><br />
                    <!--a href="https://babybel.nl" style="text-decoration: underline;">Mijn cookievoorkeuren aanpassen</a --></p>
                <button style="float: right;" @click="accept">Alle cookies aanvaarden</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .cookies {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba( 0, 0, 0, 0.5 );
        z-index: 5;
        display: flex;
        overflow-y: scroll;

        .message {
            max-width: 600px;
            background: white;
            padding: 1rem;
            margin: auto;
            text-align: left;

            p{
                font-size:1.2rem;
            }
        }
    }
</style>

<script>
    export default {
        data()
        {
            return {
                cookiesAccepted: false
            };
        },
        computed: {
            cookies()
            {
                return window.localStorage.getItem( 'cookies' ) || this.cookiesAccepted;
            }
        },
        methods: {
            accept()
            {
                window.localStorage.setItem( 'cookies', true );
                this.cookiesAccepted = true;
            }
        }
    };
</script>
