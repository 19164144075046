export default {
    namespaced: true,

    state: {
        stories: [

            {
                release: new Date( '2021-08-02T00:00:00' ),
                title: 'Dex is sraap',
                duration: 343,
                description: 'De dag van Sam begint niet goed. Alles wat mis kan gaan, gaat mis. Sam komt te laat op school, heeft moeite met de woorden uit het dictee en is thuis de lunch vergeten. Naast Sam zit Dex. Dex moet altijd om álles lachen en krijgt dan een paars gezicht. Kan Dex Sam weer aan het lachen maken?',
                image: '/static/img/stories/story2.png',
                audiosrc: '/static/audio/dex-is-sraap.mp3',
                animData: '/static/json/story2.json'
            },

            {
                release: new Date( '2021-08-18T12:00:00' ),
                title: 'Een heel hoog huis',
                duration: 413,
                description: 'Er zit een nieuwe jongen in de klas van Sam: Tino. Tino komt uit Ghana en zegt nog niet zoveel. Net als hij begint te praten over vroeger, wil de juf verder met de les. Na schooltijd springt Tino meteen op zijn fiets en racet dan snel naar huis. Sam raakt nieuwsgierig, heeft Tino soms iets te verbergen? Sam gaat hem achterna...',
                image: '/static/img/stories/story1.png',
                audiosrc: '/static/audio/heel-hoog-huis.mp3',
                animData: '/static/json/story1.json'
            },

            {
                release: new Date( '2021-09-01T12:00:00' ),
                title: 'Holletjes in een krullenbos',
                duration: 380,
                description: 'Sam zit tijdens de rekentoets naast Aïcha. Ze heeft een prachtige bos met krullen. Aïcha vindt haar krullen zelf vooral onhandig. Samen bedenken ze een slim plan om haar mooie bos met krullen een functie te geven. Ze zijn zelf erg trots op hun plan, maar voor hoelang? Hopelijk heeft de juf niks door...',
                image: '/static/img/stories/story3.png',
                audiosrc: '/static/audio/holletjes-in-een-krullenbos.mp3',
                animData: '/static/json/story3.json'
            },

            {
                release: new Date( '2021-09-08T12:00:00' ),
                title: 'Tok tok',
                duration: 336,
                description: 'Sam en vriend Tarik zitten samen op de fiets. Ze zijn onderweg naar het huis van ouwe Boet. Daar zijn de krukjes gemaakt van pompoen en de limonadeglazen van kokosnoot. Opeens trapt Tarik keihard op zijn rem. Hij ziet iets waardoor hij niet meer verder wil. Hoe kan Sam hem nu helpen?',
                image: '/static/img/stories/story4.png',
                audiosrc: '/static/audio/tok-tok.mp3',
                animData: '/static/json/story4.json'
            },

            {
                release: new Date( '2021-09-15T12:00:00' ),
                title: 'Hoe klinkt een lange nek?',
                duration: 407,
                description: 'Sam en de kinderen uit de klas mogen op de knutselzolder eten. Sam zit naast Eva, het stilste meisje uit de klas. Eva staart naar haar geknutselde giraffenkop, terwijl ze een blaadje sla in haar mond steekt. Sam vraagt zich af waarom Eva alleen maar blaadjes sla eet. Als Eva vertelt waarom, valt de mond van Sam open. Is dat echt waar? En hoe klinkt dat dan?',
                image: '/static/img/stories/story5.png',
                audiosrc: '/static/audio/hoe-klinkt-een-lange-nek.mp3',
                animData: '/static/json/story5.json'
            }
        ]
    },

    getters: {
        all: state => state.stories
    }
};
