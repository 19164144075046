import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import stories from './modules/stories';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry,
        stories
    },

    state: {
        access: false
    },

    getters: {
        access: ({ access }) => access
    },

    mutations: {
        allowAccess( state, payload )
        {
            state.access = payload;
        }

    },

    actions: {
        allowAccess( context, payload )
        {
            context.commit( 'allowAccess', payload );
        }
    }
});
